/* .App{
  background-color: #29aae1;
  height: 100%;
} */

body{
  background-color: #29aae1;
  height: 100%;

}

.remove-class{
  color: red!important;
  font-size: 16!important;
}

.zoom-bigger{
  transition:all .3s ease;
}

.zoom-bigger:hover{
  transform:translateZ(0) scale(1.4);
}

.zoom-font{
  transition:all 0.3s ease;
}

.zoom-font:hover{
  transform:translateZ(0) scale(1.3);
}

.header-timesheet{
  background-color: #29aae1;
  color:#fff;
  padding: 20px;
  font-size: 18px;
}

.table-custom-list tbody >tr > td{
  font-size: 15px!important;
  padding: 20px 8px ;
}

.table-custom-list{
  border: 1px solid #29aae1;
}

.pending-badge{
  background-color: red;
  color: #FFFF;
  /* width: 25px;
  height: 25px; */
  text-align: center;
  border-radius: 3px;
}

.success-badge{
  background-color: lightgreen;
  color: #FFFF;
  /* width: 25px;
  height: 25px; */
  text-align: center;
  border-radius: 3px;
}

.paid-badge{
  color:lightgreen!important;
  font-size: 27px;
}

.login-pass input{
  text-align: center!important;
}